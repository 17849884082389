import React, { useEffect } from 'react'
import { CssBaseline, ThemeProvider, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import Env from '../../shared/config/envConfig'
import appConfig from '../../shared/config/appConfig'
import { getAppTheme } from '../../shared/config/getAppTheme'
import SnackbarComponent from '../../shared/components/MessageComponents/SnackbarComponent'
import { getPrefersDarkMode, getRedirectPath, getUser } from '../../store/settings/settings.selectors'
import { Route, Switch } from 'react-router'
import TableActionButtons from './TableActionButtons'

// Import CRUDE
import { CrudeConfiguration } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'
import { useCrude, CrudeContext } from '@3m5/crude-frontend/dist/crudeHooks'
import { crudeRootReducer } from '@3m5/crude-frontend/dist/store/crudeRoot.reducer'

import Login from '../../shared/components/Login'
import { useHistory } from 'react-router-dom'
import { setUser } from '../../store/settings/settings.actions'
import HeaderComponent from './Header/HeaderComponent'
import Settings from './Settings'
import crudeLicense from '../../shared/config/crudeLicense'
import crudeTabConfig from '../../shared/config/crudeTabConfig'
import { createStore } from 'redux'
import Dashboard from './Dashboard'
import editConfig from '../../shared/config/editConfig'
import { addCsrfHeaders, CSRF_HEADER, getCsrfToken } from '../../shared/config/csrf'

const App: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const store2 = createStore(
    crudeRootReducer,
  )

  const user = useSelector(getUser)
  const darkMode = useSelector(getPrefersDarkMode)
  const redirectPath = useSelector(getRedirectPath)

  const appName = 'license'

  useEffect(() => {
    const oldMode = localStorage.getItem('darkMode') === 'true'
    store2.getState().crudeStore.darkMode = darkMode
    if (oldMode !== darkMode) {
      localStorage.setItem('darkMode', darkMode ? 'true' : 'false')
      location.reload()
    }
  },
  [darkMode],
  )

  useEffect(() => {
    let pathname = '/'

    if (!user) {
      pathname = 'login'
    } else {
      if (redirectPath) {
        history.push(redirectPath)
      }
    }

    switch (pathname) {
      case 'login':
        history.push('/login')
        break
      default:
        history.push('/')
        break
    }
  }, [history, user],
  )

  const crudeConfiguration: CrudeConfiguration = {
    theme: getAppTheme(darkMode),
    path: `/${appName}`,
    api: {
      app: appName,
      restPath: `${Env.crudeRestBase}/api/crude/`,
      headers: [
        {
          name: CSRF_HEADER,
          getHeader: (): string => getCsrfToken(),
        },
      ],
    },
    appLayout: appConfig(),
    editLayout: editConfig(),
    crudeLicense: crudeLicense(),
    tabs: crudeTabConfig(),
  }

  const basePath = Env.basePath
  const requestHeaders: HeadersInit = new Headers()
  requestHeaders.set('Accept', 'application/json')
  requestHeaders.set('Content-Type', 'application/json')
  addCsrfHeaders(requestHeaders)

  const onLogout = () => {
    const restUrl = `${basePath}/api/session/logout`
    fetch(restUrl,
      {
        method: 'POST',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(() => dispatch(setUser(undefined)))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    const restUrl = `${basePath}/api/session/user`
    fetch(restUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        !json.error && dispatch(setUser(json))
      })
      .catch(err => console.log(err))
  },
  [],
  )

  window.addEventListener('CrudeLogout', (() => {
    onLogout()
  }) as EventListener)

  const SettingsApp = () => (<Settings />)
  const DashboardApp = () => (<Dashboard />)
  const crudeContext = useCrude({ appPageProps: crudeConfiguration, tableActionComponent: TableActionButtons })
  return (
    <>
      <CrudeContext.Provider value={crudeContext}>
        <ThemeProvider theme={getAppTheme(darkMode)}>
          <CssBaseline />
          <Stack spacing={2} sx={{ width: '100%', padding: '0 20px' }}>
            <div className='main'>
              <>
                <HeaderComponent doLogout={() => onLogout()} />
              </>
              <Switch>
                <Route exact path='/' component={DashboardApp} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/settings' component={SettingsApp} />
                <Route key='crude' path={`/${appName}`} component={crudeContext.Crude} />
              </Switch>
            </div>
            {TableActionButtons}
            <SnackbarComponent />
          </Stack>
        </ThemeProvider>
      </CrudeContext.Provider>
    </>
  )
}

export default App
