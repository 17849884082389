import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Theme } from '@mui/material/styles'

import {
  setAppLayout,
  setEditLayout,
  setPrefersDarkMode,
  setTheme,
  setCrudeAppName, setMessage, setUser, setRedirectPath,
} from './settings.actions'
import { createTheme } from '@mui/material'
import { Message } from '@3m5/crude-frontend/dist/shared/types/messageTypes'
import { AppLayout, EditLayout } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'
import { ApiError } from '../root.reducer'
import produce from 'immer'

export interface SettingsState {
  fetching: boolean
  prefersDarkMode: boolean
  editLayout?: EditLayout
  appLayout?: AppLayout
  theme: Theme
  user?: any
  crudeAppName: string
  message?: Message
  error: ApiError
  redirectPath?: string
}

const INITIAL_SETTINGS_STATE: SettingsState = {
  fetching: false,
  crudeAppName: 'license',
  prefersDarkMode: localStorage.getItem('darkMode') === 'true',
  theme: createTheme(),
  error: {},
}

export const settingsStateReducer = reducerWithInitialState(INITIAL_SETTINGS_STATE)
  .case(setPrefersDarkMode, (state: SettingsState, prefersDarkMode: boolean) => {
    return ({ ...state, prefersDarkMode })
  })
  .case(setEditLayout, (state: SettingsState, editLayout: EditLayout) => {
    return ({ ...state, editLayout })
  })
  .case(setAppLayout, (state: SettingsState, appLayout: AppLayout) => {
    return ({ ...state, appLayout })
  })
  .case(setTheme, (state: SettingsState, theme: Theme) => {
    return ({ ...state, theme })
  })
  .case(setCrudeAppName, (state: SettingsState, crudeAppName: string) => {
    return ({ ...state, crudeAppName })
  })
  .case(setMessage, (state, message) => {
    return produce(state, (draft) => {
      draft.message = message
    })
  })
  .case(setUser, (state: SettingsState, user: any) => {
    return ({ ...state, user })
  })
  .case(setRedirectPath, (state, redirectPath) => {
    return ({ ...state, redirectPath })
  })
