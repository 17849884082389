import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Env from '../../shared/config/envConfig'
import {
  Grid,
  Typography,
  Box,
  useTheme,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, Divider, Dialog, DialogActions, Button, DialogContent, DialogTitle, useMediaQuery,
} from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import CardComponent from './CardComponent'

import ReportIcon from '@mui/icons-material/Report'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import UpdateIcon from '@mui/icons-material/Update'

import formatDate from '@3m5/crude-frontend/dist/shared/util/formatDate'
import { useTranslation } from 'react-i18next'
import { addCsrfHeaders } from '../../shared/config/csrf'

const useStyles = makeStyles(() => ({
  root: {
    margin: '40px 0',
  },
}))

const Dashboard: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const history = useHistory()

  const requestHeaders: HeadersInit = new Headers()
  requestHeaders.set('Accept', 'application/json')
  requestHeaders.set('Content-Type', 'application/json')
  addCsrfHeaders(requestHeaders)

  const StyledTableRow = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '& td, th': {
      border: 0,
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }))

  const StyledTableHead = styled(TableRow)(() => ({
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '& td, th': {
      border: 0,
    },
  }))

  const [showDeleteRequest, setShowDeleteRequest] = useState<boolean>(false)
  const [deleteInstallation, setDeleteInstallation] = useState<string | undefined>()

  const [data, setData] = useState<any>()

  const getDashboardData = () => {
    const restUrl = `${Env.basePath}/api/dashboard`
    fetch(restUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        setData(json)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getDashboardData()
  }, [],
  )

  const deleteInstallationRequest = () => {
    const restUrl = `${Env.basePath}/api/delete/installation/${deleteInstallation}`
    fetch(restUrl,
      {
        method: 'DELETE',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(() => {
        getDashboardData()
        setShowDeleteRequest(false)
        setDeleteInstallation(undefined)
      })
      .catch(err => console.log(err))
  }
  const getInstalltionAction = (installationId: string) => {
    return (
      <Box>
        <EditIcon
          sx={{ color: theme.palette.primary.main, cursor: 'pointer', marginRight: '5px' }}
          onClick={() => {
            history.push(`license/Installation/${installationId}`)
          }}
        />
        <DeleteOutlineIcon
          sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
          onClick={() => {
            setShowDeleteRequest(true)
            setDeleteInstallation(installationId)
          }}
        />
      </Box>
    )
  }

  const getLicenseAction = (licenseId: string) => {
    return (
      <Box>
        <UpdateIcon
          sx={{ color: theme.palette.primary.main, cursor: 'pointer', marginRight: '5px' }}
          onClick={() => {
            // TODO Was soll hier passieren?
          }}
        />
        <EditIcon
          sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
          onClick={() => {
            history.push(`license/License/${licenseId}`)
          }}
        />
      </Box>
    )
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h4'>
              Willkommen in der 3m5. Lizenzverwaltung
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={downMd ? 12 : 4}>
              <CardComponent padding={0} height={downMd ? 'unset' : 280}>
                <Stack spacing={1}>
                  <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px 8px 0 8px' }}>
                    <ReportIcon sx={{ marginRight: '5px', height: '1.5em', width: '1.5em', color: theme.palette.error.main }} />
                    <Typography sx={{ padding: downMd ? 2 : 0, paddingBottom: 0 }} variant='h6'>
                      <b>
                        Installationen ohne Lizenz
                      </b>
                    </Typography>
                  </Box>
                  <Divider />
                  <TableContainer>
                    <Table>
                      {!downMd &&
                        <TableHead>
                          <StyledTableHead>
                            <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Kunde</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Aktionen</TableCell>
                          </StyledTableHead>
                        </TableHead>}
                      <TableBody>
                        {data && data.installationsWithoutLicense?.map((installation: any, index: number) => (
                          <>
                            {installation.customer &&
                              <StyledTableRow
                                key={index}
                              >
                                {downMd ? (
                                  <TableCell>
                                    <Grid container spacing={1}>
                                      <Grid item xs={6}>Name</Grid>
                                      <Grid item xs={6}><Typography>{installation.installationName}</Typography></Grid>
                                      <Grid item xs={6}>Kunde</Grid>
                                      <Grid item xs={6}><Typography>{installation.customer}</Typography></Grid>
                                      <Grid item xs={6}>Aktionen</Grid>
                                      <Grid item xs={6}><Typography>{getInstalltionAction(installation.installationId)}</Typography></Grid>
                                    </Grid>
                                  </TableCell>)
                                  : (
                                    <>
                                      <TableCell>{installation.installationName}</TableCell>
                                      <TableCell>{installation.customer}</TableCell>
                                      <TableCell>{getInstalltionAction(installation.installationId)}</TableCell>
                                    </>)}
                              </StyledTableRow>}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </CardComponent>
            </Grid>
            <Grid item xs={downMd ? 12 : 4}>
              <CardComponent padding={0} height={downMd ? 'unset' : 280}>
                <Stack spacing={1}>
                  <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px 8px 0 8px' }}>
                    <ReportIcon sx={{ marginRight: '5px', height: '1.5em', width: '1.5em', color: theme.palette.error.main }} />
                    <Typography sx={{ padding: downMd ? 2 : 0, paddingBottom: 0 }} variant='h6'>
                      <b>
                        Manuelle Installationen ohne Kunde
                      </b>
                    </Typography>
                  </Box>
                  <Divider />
                  <TableContainer>
                    <Table>
                      {!downMd &&
                        <TableHead>
                          <StyledTableHead>
                            <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Installationskey</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Aktionen</TableCell>
                          </StyledTableHead>
                        </TableHead>}
                      <TableBody>
                        {data && data.installationsWithoutCustomer?.map((installation: any, index: number) => (
                          <>
                            {!installation.customer &&
                              <StyledTableRow
                                key={index}
                              >
                                {downMd ? (
                                  <TableCell>
                                    <Grid container spacing={1}>
                                      <Grid item xs={6}>Name</Grid>
                                      <Grid item xs={6}><Typography>{installation.installationName}</Typography></Grid>
                                      <Grid item xs={6}>Installationskey</Grid>
                                      <Grid item xs={6}><Typography>{installation.installationKey}</Typography></Grid>
                                      <Grid item xs={6}>Aktionen</Grid>
                                      <Grid item xs={6}><Typography>{getInstalltionAction(installation.installationId)}</Typography></Grid>
                                    </Grid>
                                  </TableCell>)
                                  : (
                                    <>
                                      <TableCell>{installation.installationName}</TableCell>
                                      <TableCell>{installation.installationKey}</TableCell>
                                      <TableCell>{getInstalltionAction(installation.installationId)}</TableCell>
                                    </>)}
                              </StyledTableRow>}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </CardComponent>
            </Grid>
            {/* <Grid item xs={downMd ? 12 : 6}>
              <CardComponent padding={0} height={downMd ? 'unset' : 280}>
                <Stack spacing={1}>
                  <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px 8px 0 8px' }}>
                    <ReportIcon sx={{ marginRight: '5px', height: '1.5em', width: '1.5em', color: theme.palette.error.main }} />
                    <Typography sx={{ padding: downMd ? 2 : 0, paddingBottom: 0 }} variant='h6'>
                      <b>
                        Abgelaufene Lizenzen
                      </b>
                    </Typography>
                  </Box>
                  <Divider />
                  <TableContainer>
                    <Table>
                      {!downMd &&
                        <TableHead>
                          <StyledTableHead>
                            <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>abgelaufen am</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Aktionen</TableCell>
                          </StyledTableHead>
                        </TableHead>}
                      <TableBody>
                        {data && data.expiredLicenses?.map((license: any, index: number) => (
                          <>
                            <StyledTableRow
                              key={index}
                            >
                              {downMd ? (
                                <TableCell>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6}>Name</Grid>
                                    <Grid item xs={6}><Typography>{license.name}</Typography></Grid>
                                    <Grid item xs={6}>abgelaufen am</Grid>
                                    <Grid item xs={6}><Typography>{formatDate(new Date(license.expires).getTime() / 1000)}</Typography></Grid>
                                    <Grid item xs={6}>Aktionen</Grid>
                                    <Grid item xs={6}><Typography>{getLicenseAction(license.id)}</Typography></Grid>
                                  </Grid>
                                </TableCell>)
                                : (
                                  <>
                                    <TableCell>{license.name}</TableCell>
                                    <TableCell>{formatDate(new Date(license.expires).getTime() / 1000)}</TableCell>
                                    <TableCell>{getLicenseAction(license.id)}</TableCell>
                                  </>)}
                            </StyledTableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </CardComponent>
            </Grid> */}
            <Grid item xs={downMd ? 12 : 4}>
              <CardComponent padding={0} height={downMd ? 'unset' : 280}>
                <Stack spacing={1}>
                  <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px 8px 0 8px' }}>
                    <ReportProblemIcon sx={{ marginRight: '5px', height: '1.5em', width: '1.5em', color: theme.palette.warning.main }} />
                    <Typography sx={{ padding: downMd ? 2 : 0, paddingBottom: 0 }} variant='h6'>
                      <b>
                        Lizenz endet bald
                      </b>
                    </Typography>
                  </Box>
                  <Divider />
                  <TableContainer>
                    <Table>
                      {!downMd &&
                        <TableHead>
                          <StyledTableHead>
                            <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>läuft ab am</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Aktionen</TableCell>
                          </StyledTableHead>
                        </TableHead>}
                      <TableBody>
                        {data && data.soonExpiredLicenses?.map((license: any, index: number) => (
                          <>
                            <StyledTableRow
                              key={index}
                            >
                              {downMd ? (
                                <TableCell>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6}>Name</Grid>
                                    <Grid item xs={6}><Typography>{license.name}</Typography></Grid>
                                    <Grid item xs={6}>läuft ab am</Grid>
                                    <Grid item xs={6}><Typography>{formatDate(new Date(license.expires).getTime() / 1000)}</Typography></Grid>
                                    <Grid item xs={6}>Aktionen</Grid>
                                    <Grid item xs={6}><Typography>{getLicenseAction(license.id)}</Typography></Grid>
                                  </Grid>
                                </TableCell>)
                                : (
                                  <>
                                    <TableCell>{license.name}</TableCell>
                                    <TableCell>{formatDate(new Date(license.expires).getTime() / 1000)}</TableCell>
                                    <TableCell>{getLicenseAction(license.id)}</TableCell>
                                  </>)}
                            </StyledTableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </CardComponent>
            </Grid>
            <Grid item xs={12}>
              <CardComponent padding={0}>
                <Stack spacing={1}>
                  <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px 8px 0 8px' }}>
                    <ReportProblemIcon sx={{ marginRight: '5px', height: '1.5em', width: '1.5em', color: theme.palette.warning.main }} />
                    <Typography sx={{ padding: downMd ? 2 : 0, paddingBottom: 0 }} variant='h6'>
                      <b>
                        Neuesten 10 Error Logs
                      </b>
                    </Typography>
                  </Box>
                  <Divider />
                  <TableContainer>
                    <Table>
                      {!downMd &&
                        <TableHead>
                          <StyledTableHead>
                            <TableCell sx={{ fontWeight: 600 }}>Fehler</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Lizenzschlüssel</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Installationsname</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Installationsschlüssel</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Produktschlüssel</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Domain</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>erstellt am</TableCell>
                          </StyledTableHead>
                        </TableHead>}
                      <TableBody>
                        {data && data.errorLogs?.map((error: any, index: number) => (
                          <>
                            <StyledTableRow
                              key={index}
                            >
                              {downMd ? (
                                <TableCell>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6}>Fehler</Grid>
                                    <Grid item xs={6}><Typography>{t(`error:${error.error}`)}</Typography></Grid>
                                    <Grid item xs={6}>Lizenzschlüssel</Grid>
                                    <Grid item xs={6}><Typography>{error.licenseKey}</Typography></Grid>
                                    <Grid item xs={6}>Installationsname</Grid>
                                    <Grid item xs={6}><Typography>{error.installationName}</Typography></Grid>
                                    <Grid item xs={6}>Installationsschlüssel</Grid>
                                    <Grid item xs={6}><Typography>{error.installationKey}</Typography></Grid>
                                    <Grid item xs={6}>Produktschlüssel</Grid>
                                    <Grid item xs={6}><Typography>{error.productKey}</Typography></Grid>
                                    <Grid item xs={6}>Domain</Grid>
                                    <Grid item xs={6}><Typography>{error.domain}</Typography></Grid>
                                    <Grid item xs={6}>erstellt am</Grid>
                                    <Grid item xs={6}><Typography>{formatDate(new Date(error.createdAt).getTime() / 1000)}</Typography></Grid>
                                  </Grid>
                                </TableCell>)
                                : (
                                  <>
                                    <TableCell>{t(`error:${error.error}`)}</TableCell>
                                    <TableCell>{error.licenseKey}</TableCell>
                                    <TableCell>{error.installationName}</TableCell>
                                    <TableCell>{error.installationKey}</TableCell>
                                    <TableCell>{error.productKey}</TableCell>
                                    <TableCell>{error.domain}</TableCell>
                                    <TableCell>{formatDate(new Date(error.createdAt).getTime() / 1000)}</TableCell>
                                  </>)}
                            </StyledTableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </CardComponent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={showDeleteRequest}
      >
        <DialogTitle>
          Installation löschen
        </DialogTitle>
        <DialogContent>
          <Typography variant='body1'>Wollen Sie diese Installation wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden!</Typography>

        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            onClick={() => {
              setShowDeleteRequest(false)
              setDeleteInstallation(undefined)
            }}
          >
            Abbrechen
          </Button>
          <Button
            color='primary'
            onClick={() => {
              deleteInstallationRequest()
            }}
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default Dashboard
