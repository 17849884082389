{
  "common": {
    "editProfile": "Profil bearbeiten",
    "settings": "Einstellungen",
    "dashboard": "Übersicht",
    "logout": "Abmelden",
    "save": "Speichern",
    "cancel": "Abbrechen",
    "submit": "Ok",
    "sort": "Sortieren",
    "back": "zurück zur Übersicht",
    "export": "Produkte exportieren",
    "download": "Download",
    "copyLicense": "Lizenz kopieren"
  },
  "error": {
    "licenseKeyInvalid": "Lizenschlüssel ungültig",
    "domainInvalid": "Domain ungültig",
    "licenseExhausted": "Lizenz ausgeschöpft",
    "installatonWithOtherKey":  "Ungültige Kombination Installation",
    "productKeyInvalid":  "Produkt ungültig"
  },
  "settings": {
    "deleteSecret": "Secret löschen",
    "deleteSecretHint": "Sind Sie sicher, dass sie das Secret des Nutzers löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden!",
    "username": "Nutzername",
    "email": "E-Mail-Adresse",
    "name": "Klartextname",
    "role": "Rolle",
    "phone": "Telefon",
    "customer": "Kunde",
    "changePassword": "Passwort ändern",
    "oldPassword": "Altes Passwort",
    "newPassword": "Neues Passwort",
    "confirmPassword": "Neues Passwort wiederholen"
  },
  "messages": {
    "exportProductExcelFileError": "Die Liste konnte nicht exportiert werden."
  }
}
